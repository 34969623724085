.navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0;
  left: auto;
}
nav.navbar {
  padding: 8px 16px;
  background: #fff !important;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.navbar-toggler-icon {
  cursor: pointer;
}
a#basic-nav-dropdown {
  font-size: 14px;
}
ul.pcoded-item.pcoded-left-item {
  padding: 0;
  margin: 0;
}
ul.pcoded-item li a {
  display: block;
  padding: 12px 20px;
  color: #222;
}
