.error-message {
  color: rgb(255, 93, 93);
  padding-left: 5px;
  font-size: 12px;
  margin-top: 2px;
}
.invalid-Login {
  color: rgb(255, 93, 93);
  font-size: large;
}
.login_left_panel {
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 50px;
}
.login_form {
  position: relative;
  padding: 35px !important;
}
.auth-box h1 {
  font-weight: bold;
}
.auth-box label {
  font-size: 14px;
  color: #666;
}
.login_lead_txt {
  font-size: 15px;
  color: #444;
}
.login_logo img {
  height: 100vh;
}
.form-primary .form-control:focus {
  box-shadow: none;
}
