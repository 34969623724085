
.event-total-user-table .from-control {
    background: #fff !important;
    border: 1px solid #ced4da;
    color: #000 !important;
    justify-content: space-between;
    padding: 5px 8px;
    width: 80px;
}

.event-total-user-table {
    display: flex;
    align-items: center;
}

.event-total-user-table label {
    width: 50px;
}

.react-event-table tr th {
    background-color: rgba(20, 37, 103, 0.1);
    color: rgb(0, 0, 0);
    font-weight: 700;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
}

.react-event-table tr td {
    background-color: #fff !important;
}

.pagination .active .page-link {
    background-color: rgb(223, 96, 83);
    color: white;
    border-radius: 25px;
    font-size: 14px;
    margin: 2px;
    border-radius: 20px !important;
}

.pagination .page-link {
    border: none;
}

.pagination .page-item a {
    color: black !important;
}

.react-event-table table {
    table-layout: fixed;

}

.react-event-table thead th {
    position: sticky;
    top: 0;
    background: #e7e8ef !important;
    z-index: 9;
}

.react-event-table {
    max-height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #e7e8ef !important;
}

.react-event-table thead tr {
    border-top: 0;
}

.page-drop-down {
    appearance: auto !important;
    padding-inline: 10px !important;
    margin-inline: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
}

.sort-icon{
    margin-left: 5px;
    cursor: pointer;
}

.react-event-table::-webkit-scrollbar {
    display: none;
}

.event_status-class{
    display: flex;
    margin-inline: 10px;
    align-items: center;
}

.event_status-class label{
    margin-inline: 10px;
}

.header-title-class{
    cursor: pointer;
}

.data-title-class{
    max-height: 355px;
}

.title-label-class{
    cursor: pointer;
}




