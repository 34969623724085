@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.2.1/css/fontawesome.min.css");

* {
  font-family: mulish;
}

nav.pcoded-navbar {
  width: 210px;
  background: #fff;
  min-height: calc(100vh - 56px);
  position: fixed;
}

.pcoded-navbar-close {
  width: 100px;
  background: #fff;
  min-height: calc(100vh - 56px);
}

img {
  max-width: 100%;
}

.pcoded-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.pcoded-content {
  position: relative;
  width: calc(100% - 210px);
  padding: 30px;
  margin-left: 210px;
}

.pcoded-content-full {
  position: relative;
  width: 100%;
  padding: 30px;
}

.pcoded-main-container {
  background: #eff5f7;
  margin-top: 56px;
  min-height: calc(100vh - 56px);
}

/* .mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
} */
.form-group {
  position: relative;
  margin-bottom: 15px;
}

ul.pcoded-item li a:hover {
  /* background: #142567; */
  background: #df6053;
  color: #fff;
}

ul.pcoded-item li a {
  transition: all 0.3s;
}

.input-full-width {
  width: 100%;
}

.small-btn {
  --bs-btn-padding-y: 0.25rem !important;
  --bs-btn-padding-x: 0.5rem !important;
  --bs-btn-font-size: 0.75rem !important;
  height: -webkit-fill-available !important;
}

.bread-top {
  margin-top: -40px;
  padding-left: 40px !important;
  margin-left: -40px;
  margin-right: -40px;
  padding: 12px;
  background-color: #ffffff;
  margin-bottom: 25px;
}

.event-title-open {
  color: rgb(32 67 203) !important;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.text-input {
  max-height: 60px;
  padding-left: 6px;
}

.m-body {
  padding: 0 !important;
}

.full-width {
  width: 100%;
}

.dropdown-item a {
  text-decoration: none;
  color: black;
}

.user-id-table {
  width: 50px;
}

.label {
  font-weight: bold;
}

.question-form-control {
  min-height: calc(5.5em + 0.75rem + 2px) !important;
}

.breadcrumb {
  font-size: 14px !important;
}

.breadcrump-link {
  text-decoration: none;
  color: var(--bs-breadcrumb-item-active-color);
}

.breadcrumb-item.active {
  color: rgb(20, 37, 103) !important;
  font-weight: 700;
}

.breadcrumb {
  margin: 0 !important;
}



.draggable-class {
  height: calc(100vh - 190px);
  overflow-y: auto;
}
/* .table-style {
  overflow-y: scroll;
  height: 60vh;
  width: 100%;
} */
.react-bootstrap-table thead th {
    position: sticky;
    top: 0;
    background: #e7e8ef !important;
    z-index: 9;
}
.react-event-table-class{
  max-height:450px;
  overflow-y: auto;
}
.react-bootstrap-table thead tr{
  border-top: 0;
}

/* table css */

.page_header h2 {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 20px;
}

._loading_overlay_wrapper table>tbody {
  overflow-y: overlay;
  max-height: 45vh !important;
  display: block;
  border-bottom: 0.5px solid var(--bs-border-color-translucent);
}

.pagination_active {
  background-color: rgb(20, 37, 103) !important;
  /* background-color: #df6053 !important; */
  color: #fff !important;
}

.pagination_inactive {
  background-color: rgba(20, 37, 103, 0.1) !important;
  /* background-color: #df6053 !important; */
}

/* ._loading_overlay_wrapper table thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
} */


.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: none !important;
  border-bottom: none !important;
  --bs-table-accent-bg: none !important;
  vertical-align: middle;
}

.table> :not(caption)>*>* {
  vertical-align: middle;
}

.table-bordered {
  background-color: none !important;
  border-bottom: none !important;
}

.table-bordered> :not(caption)>* {
  background-color: none !important;
  border-bottom: none !important;
}

/* .table-bg-col td {
  padding: 10px 18px !important;
  width: 20%;
} */


.table>:not(caption)>*>* {
  background-color: var(--bs-table-bg);
  box-shadow: none !important;
}

.table-bg-col-event td {
  padding: 8px 12px !important;
}

.sort-false {
  color: #929292;
}



/* .table-bg-col-event td:nth-child(1) {
  width: 12% !important;
} */
.table-bg-col-event td:nth-child(1) {
  width: 16% !important;
}

.table-bg-col-event td:nth-child(2) {
  width: 20% !important;
}

.table-bg-col-event td:nth-child(3) {
  width: 15% !important;
}

.table-bg-col-event td:nth-child(4) {
  width: 15% !important;
}

.table-bg-col-event td:nth-child(5) {
  width: 16% !important;
}

.table-bg-col-event td:nth-child(6) {
  width: 18% !important;
}

.table-bg-col td {
  padding: 8px 12px !important;
}

.table-bg-col td:nth-child(1) {
  width: 10% !important;
}

.table-bg-col td:nth-child(2) {
  width: 30% !important;
}

.table-bg-col td:nth-child(3) {
  width: 30% !important;
}

.react-bootstrap-table-pagination {
  display: flex !important;
  align-items: center;
  margin-top: 20px !important;
  border-bottom: 1px solid var(--bs-table-bg);
}

.react-bootstrap-table-pagination-list ul {
  margin-bottom: 0px;
}

.Search-user-table {
  margin: 10px;
}

.total-user-table {
  /* margin: 15px 0px; */
  width: 273px;
  /* background-color: #009d055e; */
  padding: 3px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.total-user-table .btn {
  background: white !important;
  color: black !important;
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 5px 8px;
  border: 1px solid #ced4da;
}


/* .table-parent{
  overflow-x: scroll;
}

.table-parent table{
   width: 1400px; 
  
  
} */
.react-bootstrap-table {
  font-size: 14px;
}

.pagination .page-link:hover{
  background-color: #fff !important;
}

.react-bootstrap-table table{
  border-bottom: 1px solid #e7e8ef !important;
}
.pagination-event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination-no ul {
  margin-bottom: 0px;
}

/* .search-label {
  margin: 10px;
} */
.table-top-body {
  display: flex;
  justify-content: space-between;
}

.pagination {
  justify-content: flex-end;
}

.sr-only {
  display: none;
}

.table {
  margin-bottom: 0 !important;
}

.search-table .form-control {
  font-size: 14px;
  height: 36px;
}

.search-table .form-control:focus {
  box-shadow: none;
}

.page-link:focus {
  box-shadow: none !important;
}

.total-user-table .btn:first-child:active {
  border-color: #86b7fe !important;
}

.total-user-table .btn:first-child:hover {
  border-color: #86b7fe !important;
}

.total-user-table .dropdown-toggle::after {
  color: rgb(20, 37, 103);
  /* color: #df6053; */
}

/* .table-striped tr:first-child {
  position: sticky;
  top: 0;
  background: white;
} */
/* toggle */

/* .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
} */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 27px;
  outline: none;
}

.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

/* .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
} */
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  /* background-color: rgb(20, 37, 103); */
  background-color: #df6053;
}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(20, 37, 103);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.table-search-bar {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 15px 0px;
}

.react-bootstrap-table-container-body {
  height: 200px !important;
  overflow-y: overlay !important;
}

._loading_overlay_wrapper table>tbody::-webkit-scrollbar {
  display: none;
}

/* .table-card {
  overflow-y: scroll;
  height: 74vh !important;
} */

.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 100px 100px;
}

/*----User Profile --*/

.profile-dis p {
  color: #6c757d;
  font-weight: 300;
}

.profile_info p {
  color: #212529;
}

/* -----event Details--- */

.event-detail p {
  font-size: 16px;
}

.event-detail>div {
  margin-top: 15px;
}

/* payout-Modal */

.avtar {
  height: 45px;
  /* border: 1px solid; */
  background-color: #e4edf5;
  width: 45px;
  border-radius: 50%;
}

.user-name {
  color: #464646;
  margin-left: 15px;
}

.user-email {
  font-size: small;
  color: grey;
}

.para {
  margin-bottom: 0;
}

.react-bs-table-sizePerPage-dropdown {
  margin: 0px 8px;
}

.que-btn {
  /* background-color: #142567 !important; */
  background-color: #df6053 !important;
  color: #fff !important;
}

.react-bootstrap-table-pagination-total {
  font-size: 14px;
}

.pl-ul {
  margin-bottom: 0px;
}

.que-card {
  border-bottom: 1px solid black;
}

.question-btn a,
.question-btn .btn {
  font-size: 14px;
  padding: 5px 15px;
}

.modal-title h2 {
  font-weight: 800;
}

.modal-dialog .modal-title {
  font-weight: 700;
  font-size: 24px;
}

.card-subtitle {
  font-size: 14px;
}

.loader-spinner .spinner-border {
  color: rgb(20, 37, 103) !important;
}